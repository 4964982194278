import { useState } from 'react'
import { Disclosure } from '@headlessui/react'
import { nextStep } from '../../../store/step.js'
import { setPlan } from '../../../store/data.js'

const plans = [
  {
    name: 'Essential',
    description: 'Great for basic UI/UX design needs',
    price: {
      Monthly: '$3,290',
      Quarterly: '$2,961',
      Annually: '$2,796',
    },
    features: [
      { text: 'Designer working with 4 other customers' },
      { text: 'Customer Success Execution' },
      { text: 'TDP Design Feedback' },
      { text: 'Full UI Execution' },
      { text: 'Connect on Slack', slack: true }
    ]
  },
  {
    name: 'Pro',
    description: 'Great for end-to-end product design',
    popular: true,
    price: {
      Monthly: '$4,580',
      Quarterly: '$4,122',
      Annually: '$3,893',
    },
    features: [
      { text: 'Everything in Essential +', bold: true },
      { text: 'Designer working with 2 other customers' },
      { text: 'Fractional Chief Design Officer' },
      { text: 'Full UX/UI Design & Research' },
      { text: 'Dev-Ready Figma files' },
      { text: '60 min/week meeting' }
    ]
  },
  {
    name: 'Turbo',
    description: 'Ideal for complex designs with tight deadlines',
    price: {
      Monthly: '$5,990',
      Quarterly: '$5,391',
      Annually: '$5,091',
    },
    features: [
      { text: 'Everything in Essential and Pro +', bold: true },
      { text: 'Designer working with 1 other customer' },
      { text: 'Strategic Thinking Workshops' },
      { text: 'User testing' },
      { text: 'Figma prototypes' },
      { text: 'Flexible meetings' }
    ]
  },
  {
    name: 'Sprint',
    description: 'Great for immediate design improvements with deadlines',
    isFixed: true,
    price: {
      Monthly: '$3,290',
      Quarterly: '$3,290',
      Annually: '$3,290',
    },
    features: [
      { text: 'Designer working with 4 other customers' },
      { text: 'Dev-Ready Figma Files' },
      { text: '30 min/week meeting' },
      { text: 'Connect on Slack' }
    ]
  }
]

export default function Tabs() {
  const [period, setPeriod] = useState('Monthly')
  const [open, setOpen] = useState(false)

  function handlePeriod(e) {
    setPeriod(e.target.innerText)
  }

  function handleSelect(plan) {
    setPlan(plan.name, period, plan.price[period], plan.isFixed || false, false)
    nextStep()
  }

  function toggleAllDisclosures() {
    setOpen(!open)
  }

  return (
    
    <section className="bg-neutral-100">
      <div className="container px-6 pt-20 pb-8 lg:pb-[104px] mx-auto">

        <div className="relative max-w-[518px] mx-auto mb-8 lg:mb-16">
          <div className="relative grid grid-cols-3 items-center z-10">
            <div>
              <span className="w-8 h-8 text-neutral-50 bg-jungle-300 rounded-full flex items-center justify-center mb-4">1</span>
              <h6 className="text-sm lg:text-base uppercase">Choose Plan</h6>
            </div>

            <div className="flex flex-col justify-center items-center">
              <span className="w-8 h-8 text-neutral-400 bg-neutral-200 rounded-full flex items-center justify-center mb-4">2</span>
              <h6 className="text-neutral-600 text-sm lg:text-base uppercase opacity-0 lg:opacity-50">Sign Up</h6>
            </div>

            <div className="flex flex-col justify-end items-end">
              <span className="w-8 h-8 text-neutral-400 bg-neutral-200 rounded-full flex items-center justify-center mb-4">3</span>
              <h6 className="text-neutral-600 text-sm lg:text-base uppercase opacity-0 lg:opacity-50">Payment <span className="hidden lg:inline">Method</span></h6>
            </div>
          </div>

          <hr className="absolute top-4 left-0 right-0 w-full border-t-2 border-neutral-200"></hr>
        </div>

        <div className="rounded-lg bg-secondary-jungle p-1 grid grid-cols-3 gap-1 max-w-[334px] mx-auto mb-16">
          <button
            onClick={handlePeriod}
            className={`${period === 'Monthly' ? 'text-jungle-700 bg-jungle-100  border-jungle-200' : 'text-neutral-200 hover:text-neutral-50'}  p-3 leading-[20px] border border-transparent rounded transition duration-150 ease-in-out`}
          >
            Monthly
          </button>
          <button
            onClick={handlePeriod}
            className={`${period === 'Quarterly' ? 'text-neutral-700 bg-jungle-100 border-jungle-200' : 'text-neutral-200 hover:text-neutral-50'} p-3 leading-[20px] border border-transparent rounded transition duration-150 ease-in-out`}
          >
            Quarterly
          </button>
          <button
            onClick={handlePeriod}
            className={`${period === 'Annually' ? 'text-neutral-700 bg-jungle-100 border-jungle-200' : 'text-neutral-200 hover:text-neutral-50'} p-3 leading-[20px] border border-transparent rounded transition duration-150 ease-in-out`}
          >
            Annually
          </button>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 lg:gap-4">
          {plans.map((plan, index) => (
            <article key={index} className={`border-2 ${plan.name === 'Sprint' ? 'border-jungle-200' : 'border-transparent'} relative bg-neutral-50 rounded-2xl pt-10 overflow-hidden`}>
              <h3 className="text-neutral-600 text-5xl text-center leading-[52.8px] mb-6">
                {plan.name}
              </h3>
              <p className="text-neutral-500 text-xl text-center text-balance leading-[27px] max-w-[280px] mx-auto mb-12">
                {plan.description}
              </p>

              <div className="mb-8">
                <h4 className="font-display text-jungle-300 text-4xl text-center leading-[43.2px]">
                  {plan.price[period]}
                </h4>
                {!plan.isFixed && (
                  <h5 className="font-display text-neutral-500 text-2xl text-center uppercase">
                    Per month
                  </h5>
                )}
                {plan.isFixed && (
                  <h5 className="font-display text-neutral-500 text-2xl text-center uppercase font-semibold">
                    Per project
                  </h5>
                )}                
              </div>

              <div className="max-w-[245px] mx-auto mb-16">
                <button
                  onClick={() => handleSelect(plan)}
                  className="font-text text-jungle-300 hover:text-neutral-100 leading-[16px] tracking-[0.16px] font-semibold uppercase hover:bg-jungle-300 border border-jungle-300 rounded-md h-[50px] w-full focus:outline-none focus:ring focus:ring-blue-500/50 transition duration-150 ease-in-out"
                >
                  Select this plan
                </button>
              </div>

              <Disclosure>
                <Disclosure.Button className="w-full">
                  <button onClick={toggleAllDisclosures} className="text-neutral-400 uppercase bg-neutral-50 border-t border-neutral-200 py-4 px-6 flex items-center justify-between w-full">
                    What's included
                    <i className={`ph-bold ph-caret-down transform transition duration-150 ease-in-out ${open ? 'rotate-180' : 'rotate-0'}`}></i>
                  </button>
                </Disclosure.Button>
                
                {open && (
                  <Disclosure.Panel static>
                    <ul className="flex flex-col px-6 pb-2">
                      {plan.features.map((feature, i) => (
                        <li key={i} className={`${feature.bold ? 'font-semibold' : 'font-normal'} font-display text-jungle-300 leading-[20px] flex gap-2 items-center h-10`}>
                          {feature.slack ? <i className="ph-bold ph-slack-logo text-2xl"></i> : <i className="ph-bold ph-check text-2xl"></i>}
                          {feature.text}
                        </li>
                      ))}
                    </ul>
                  </Disclosure.Panel>
                )}
              </Disclosure>
            </article>
          ))}
        </div>
      </div>
    </section>
  )
}
