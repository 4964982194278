import { atom } from 'nanostores'

export const $step = atom(1)

export function setStep (step) {
  $step.set(step)
}

export function nextStep () {
  window.scrollTo(0, 0)
  $step.set($step.get() + 1)
}

export function prevStep () {
  window.scrollTo(0, 0)
  $step.set($step.get() - 1)
}