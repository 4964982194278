import Header from '../../Header'
import Special from './Special'
import Tabs from './Tabs'

export default function Pricing({ opt }) {
  return (
    <>
      <Header />
      {opt ? <Special opt={opt} /> : <Tabs />}
    </>
  )
}
