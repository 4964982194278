import { map } from 'nanostores'

export const $data = map({
  // Plan data
  plan: null,
  price: null,
  special: false,
  period: null,
  is_fixed: false,

  // User data
  company: null,
  email: null,
  firstname: null,
  lastname: null,
  legal_company: null,
  billing_address: null,
  billing_email: null,
  additional_billing_email: null
})

export function setPlan (plan, period, price, isFixed, special = false) {
  $data.set({
    ...$data.get(),
    plan,
    period,
    price,
    is_fixed: isFixed,
    special
  })
}